import React, {useEffect, useRef} from "react";
import {MENU} from "../config";
import SEO from "../components/seo";

const Loader = function () {

    const ref = useRef(null)

    useEffect(function () {
        ref.current.addEventListener('animationend', function(e) {
            if(e.animationName === "loadingOut") {
                window.location.replace(MENU.home.link);
            }
        })
    }, [ref])



    return (
        <>
            <SEO title={"Home"} />
            <div className="loader">
                <svg version="1.1" className="loader__svg"  xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" >
                    <defs>
                        <pattern id="water" width=".25" height="1.1" patternContentUnits="objectBoundingBox">
                            <path fill="#F1A820" d="M0.25,1H0c0,0,0-0.659,0-0.916c0.083-0.303,0.158,0.334,0.25,0C0.25,0.327,0.25,1,0.25,1z"/>
                        </pattern>

                        <mask id="logo_mask">
                            <path opacity="1" id="logo" fill="#ffffff" d="M97.8,25.3c-1.1-4.3-3-8.2-5.5-11.6C86.2,5.4,77.1,0,66,0h-66c-0.6,11.4,3.3,21.8,5.7,25.3
                            c5.8,8.2,15.4,13.6,26.2,13.6h34.2c3.5,0,6.4,2.8,6.4,6.4c0,0.2,0,0.5,0,0.7c-0.3,2.6-2.1,4.7-4.6,5.4c0,0-0.1,0-0.1,0h0
                            c-0.2,0.1-0.5,0.1-0.7,0.1c-0.3,0.1-0.6,0.1-1,0.1H32.7C22,51.6,12.5,57,6.8,65.2C3.2,70.4,1,76.7,1,83.5c0,0.2,0,0.5,0,0.7h0v45.6
                            h0.4c4.2-1.6,7.6-4.7,9.8-8.6c1.5-2.7,2.3-5.9,2.3-9.2V84.2c0-8.9,5.1-16.5,13.2-18.6l0,46.4c7.3-2.6,12.6-9.6,12.6-18V65.2l26.8,0
                            c14.5-0.8,22.9-7.9,26.9-13.6c2.6-3.7,4.6-8.1,5.3-12.7c0.3-1.8,0.4-3.6,0.4-5.4C98.8,30.6,98.5,27.9,97.8,25.3z M85.8,32.6
                            c0,0.2,0,0.4,0,0.6c0,0.1,0,0.3,0,0.4c0,0.2,0,0.4,0,0.5c0,0.2,0,0.4-0.1,0.6c0,0.2,0,0.3-0.1,0.5c0,0.1,0,0.3-0.1,0.4
                            c0,0.2-0.1,0.3-0.1,0.5c0,0.2-0.1,0.3-0.1,0.5c0,0,0,0,0-0.1c0,0,0,0,0-0.1c-0.4-0.8-0.8-1.5-1.2-2.2c-1.9-3.1-4.5-5.6-8.1-7.2
                            c-2.8-1.2-6.1-1.9-10-1.9l-40.7,0c-5.4-1.9-9.8-6.2-11.8-11.6h52.5c8.2,0,15.2,4.8,18.2,11.6C85.3,27.5,85.8,30,85.8,32.6L85.8,32.6
                            z"/>
                        </mask>
                    </defs>

                    <rect ref={ref} className="water-fill" mask="url(#logo_mask)" fill="url(#water)" x="-200" y="0" width="800" height="130"/>

                </svg>
            </div>
        </>

    )
}

export default Loader